import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useNavigate } from "react-router";
import timeGridPlugin from "@fullcalendar/timegrid";
import Popup from "../../Popup/Popup";
import interactionPlugin from "@fullcalendar/interaction";
import PreferedTime from "../PreferedTime/PreferedTime";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { apiHandler, getQueryParam } from "../../APIhandler/apiHandler";
import { apiList } from "../../../apiList";
import "../Calendar/Calendar.css";

const Calendar = (props) => {
  console.log(props);

  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [enablePopup, setEnablePopup] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [showInvalidDateMsg, setShowInvalidDateMsg] = useState(false);
  const [event, setEvent] = useState([]);
  const [availabilitydata, setAvailability] = useState({
    selectedDate: new Date(),
    data: [],
  });
  const [calendarDateClick, setCalendarDateClick] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [freeTime, setFreeTime] = useState([]);

  const getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  };

  const handleDateClick = (event) => {
    const getCurrDate = getCurrentDate();

    if (event.dateStr >= getCurrDate) {
      setStartTime("");
      setEndTime("");
      setCalendarDateClick(event.dateStr);
      setShowInvalidDateMsg(false);
      setModalShow(true);
      setEnablePopup(true);
    } else {
      setStartTime("");
      setEndTime("");
      setShowInvalidDateMsg(true);
      setModalShow(true);
    }
  };

  const handleMonthChnage = (e) => {
    //debugger
    getFreeTime(
      moment(e.view.currentStart).format("YYYY-MM-DD"),
      moment(e.view.currentStart).endOf("month").format("YYYY-MM-DD")
    );
    // let temp=availabilitydata;
    // temp.selectedDate=e.view.currentStart;
    // setAvailability(temp)
    //   debugger
    //   let temp =props.result;
    //   let tepData=[];
    //   debugger
    //   temp.map(x=>{
    //     x.start=x.AvailabilityStart_DtTm;
    //     x.end=x.AvailabilityEnd_DtTm;
    //     x.description=x.Subject_Val;
    //     var currDate = moment(x.start).startOf('day');
    //     var lastDate = moment(x.end).startOf('day');
    //     if(moment(x.start).format("YYYY-MM-DD")!=moment(x.end).format("YYYY-MM-DD")){
    //       tepData.push({
    //         description: x.description,

    //         eventId: 0,
    //         repAccepted: x.repAccepted,
    //         repRejcted: x.repRejcted,

    //         subject:x.subject,
    //         start:x.start,
    //         end:moment.utc(moment(moment(x.start).format("YYYY-MM-DD")+" 23:59:00Z")).format("YYYY-MM-DDTHH:mm:ss")
    //       })
    //       // console.log("start->",moment().format("YYYY-MM-DD h:mm a"));
    //       // //console.log(moment.utc(moment(moment(x.start).format("YYYY-MM-DD")+"T00:00:00")).format())
    //       // console.log("end->",moment().format("YYYY-MM-DD h:mm a"));
    //       while(currDate.add(1, 'days').diff(lastDate) < 0) {
    //         tepData.push({
    //           description: x.description,

    //         eventId: 0,
    //         repAccepted: x.repAccepted,
    //         repRejcted: x.repRejcted,

    //         subject:x.subject,
    //           start:moment.utc(moment(moment(currDate).format("YYYY-MM-DD")+" 00:00:00Z")).format("YYYY-MM-DDTHH:mm:ss"),
    //           end:moment.utc(moment(moment(currDate).format("YYYY-MM-DD")+" 23:59:00Z")).format("YYYY-MM-DDTHH:mm:ss")
    //         })
    //       }
    //       tepData.push({
    //         description: x.description,

    //         eventId: 0,
    //         repAccepted: x.repAccepted,
    //         repRejcted: x.repRejcted,

    //         subject:x.subject,
    //         start:moment.utc(moment(moment(x.end).format("YYYY-MM-DD")+" 00:00:00Z")).format("YYYY-MM-DDTHH:mm:ss"),
    //         end:x.end
    //       })

    //     }
    //     else
    //     tepData.push(x);

    //     return x;
    //   })
    // const getCurrDate = moment(e.view.currentStart).format("YYYY-MM-DD");
    //  let tempAvailabilitydata={selectedDate:e.view.currentStart,data:[]};
    //  for(var i=0;i<tepData.length;i++)
    //   {

    //     const splitval=tepData[i].start.split('T');
    //    // console.log(splitval[0]==getCurrDate.toString(),tepData[i].eventId)
    //     if(splitval[0].slice(0,7)==getCurrDate.slice(0,7) )
    //     {

    //       tempAvailabilitydata.data.push(tepData[i])
    //     }
    //   }

    //   //console.log(props.result)

    //   setAvailability(tempAvailabilitydata);
  };

  const getFreeTime = (start, end) => {
    let Id = getQueryParam("Id");
    let repId = Id.split("-")[0];
    apiHandler("get", apiList.getFreeTime + "/" + repId, {
      StartDate: new Date(start).toISOString(),
      EndDate: new Date(end).toISOString(),
    }).then((response) => {
      validateData(response.data.data, start);
      setFreeTime(response.data.data);
    });
  };
  const validateData = (data, date) => {
    let temp = data;
    let tepData = [];
    temp.map((x) => {
      x.start = x.AvailabilityStart_DtTm;
      x.end = x.AvailabilityEnd_DtTm;
      x.description = x.Subject_Val;
      x.subject = x.Subject_Val;
      var currDate = moment(x.start).startOf("day");
      var lastDate = moment(x.end).startOf("day");
      if (
        moment(x.start).format("YYYY-MM-DD") !=
        moment(x.end).format("YYYY-MM-DD")
      ) {
        tepData.push({
          description: x.description,
          eventId: 0,
          repAccepted: x.repAccepted,
          repRejcted: x.repRejcted,
          subject: x.subject,
          start: x.start,
          end: moment
            .utc(moment(moment(x.start).format("YYYY-MM-DD") + " 23:59:00Z"))
            .format("YYYY-MM-DDTHH:mm:ss"),
        });
        // console.log("start->",moment().format("YYYY-MM-DD h:mm a"));
        // //console.log(moment.utc(moment(moment(x.start).format("YYYY-MM-DD")+"T00:00:00")).format())
        // console.log("end->",moment().format("YYYY-MM-DD h:mm a"));
        while (currDate.add(1, "days").diff(lastDate) < 0) {
          tepData.push({
            description: x.description,
            eventId: 0,
            repAccepted: x.repAccepted,
            repRejcted: x.repRejcted,
            subject: x.subject,
            start: moment
              .utc(moment(moment(currDate).format("YYYY-MM-DD") + " 00:00:00Z"))
              .format("YYYY-MM-DDTHH:mm:ss"),
            end: moment
              .utc(moment(moment(currDate).format("YYYY-MM-DD") + " 23:59:00Z"))
              .format("YYYY-MM-DDTHH:mm:ss"),
          });
        }
        tepData.push({
          description: x.description,

          eventId: 0,
          repAccepted: x.repAccepted,
          repRejcted: x.repRejcted,

          subject: x.subject,
          start: moment
            .utc(moment(moment(x.end).format("YYYY-MM-DD") + " 00:00:00Z"))
            .format("YYYY-MM-DDTHH:mm:ss"),
          end: x.end,
        });
      } else tepData.push(x);
      return x;
    });
    //   debugger
    const getCurrDate = moment(date).format("YYYY-MM-DD");
    let tempAvailabilitydata = { selectedDate: date, data: [] };
    for (var i = 0; i < tepData.length; i++) {
      const splitval = tepData[i].start.split("T");
      if (splitval[0].slice(0, 7) == getCurrDate.slice(0, 7)) {
        tempAvailabilitydata.data.push(tepData[i]);
      }
    }
    setAvailability(tempAvailabilitydata);
  };
  useEffect(() => {
    getFreeTime(
      moment().format("YYYY-MM-DD"),
      moment().endOf("month").format("YYYY-MM-DD")
    );
    //   let temp =props.result;
    //   let tepData=[];
    //   temp.map(x=>{

    //     x.start=x.AvailabilityStart_DtTm;
    //     x.end=x.AvailabilityEnd_DtTm;
    //     x.description=x.Subject_Val;
    //     var currDate = moment(x.start).startOf('day');
    //     var lastDate = moment(x.end).startOf('day');
    //     if(moment(x.start).format("YYYY-MM-DD")!=moment(x.end).format("YYYY-MM-DD")){
    //       tepData.push({
    //         description: x.description,

    //         eventId: 0,
    //         repAccepted: x.repAccepted,
    //         repRejcted: x.repRejcted,

    //         subject:x.subject,
    //         start:x.start,
    //         end:moment.utc(moment(moment(x.start).format("YYYY-MM-DD")+" 23:59:00Z")).format("YYYY-MM-DDTHH:mm:ss")
    //       })
    //       // console.log("start->",moment().format("YYYY-MM-DD h:mm a"));
    //       // //console.log(moment.utc(moment(moment(x.start).format("YYYY-MM-DD")+"T00:00:00")).format())
    //       // console.log("end->",moment().format("YYYY-MM-DD h:mm a"));
    //       while(currDate.add(1, 'days').diff(lastDate) < 0) {
    //         tepData.push({
    //           description: x.description,

    //         eventId: 0,
    //         repAccepted: x.repAccepted,
    //         repRejcted: x.repRejcted,

    //         subject:x.subject,
    //           start:moment.utc(moment(moment(currDate).format("YYYY-MM-DD")+" 00:00:00Z")).format("YYYY-MM-DDTHH:mm:ss"),
    //           end:moment.utc(moment(moment(currDate).format("YYYY-MM-DD")+" 23:59:00Z")).format("YYYY-MM-DDTHH:mm:ss")
    //         })
    //       }
    //       tepData.push({
    //         description: x.description,

    //         eventId: 0,
    //         repAccepted: x.repAccepted,
    //         repRejcted: x.repRejcted,

    //         subject:x.subject,
    //         start:moment.utc(moment(moment(x.end).format("YYYY-MM-DD")+" 00:00:00Z")).format("YYYY-MM-DDTHH:mm:ss"),
    //         end:x.end
    //       })

    //     }
    //     else
    //     tepData.push(x);

    //     return x;
    //   })
    //   debugger
    //    const getCurrDate = moment(availabilitydata.selectedDate).format("YYYY-MM-DD");
    //    let tempAvailabilitydata={selectedDate:availabilitydata.selectedDate,data:[]};
    //    for(var i=0;i<tepData.length;i++)
    //     {

    //       const splitval=tepData[i].start.split('T');
    //     //  console.log(splitval[0]==getCurrDate.toString(),tepData[i].eventId)
    //       if(splitval[0].slice(0,7)==getCurrDate.slice(0,7))
    //       {

    //         tempAvailabilitydata.data.push(tepData[i])
    //       }
    //     }

    //    // console.log(props.result)

    //     setAvailability(tempAvailabilitydata);
    // //   const events=[];
    // //   for(var i=0;i<props.result.length;i++)
    // //   {
    // //         events=[{ id: props.result[i].eventId,
    // //         title: props.result[i].subject,
    // //         start: props.result[i].start,
    // //         end: props.result[i].end
    // //       }]

    // //   }
    // //   setEvent(events);
  }, []);
  // console.log(event);
  return (
    <>
      <Popup
        show={modalShow}
        inValidDate={showInvalidDateMsg}
        date={calendarDateClick}
        startTime={startTime}
        endTime={endTime}
        onHide={() => setModalShow(false)}
      />

      <div className="container calendar-content">
        <div className="row preferedtime">
          <PreferedTime
            preftime={availabilitydata}
            onClick={(e) => {
              if (moment().diff(moment(e.date), "days") > 0) {
                setShowInvalidDateMsg(true);
                setModalShow(true);
              } else {
                setShowInvalidDateMsg(false);
                setCalendarDateClick(moment().format("YYYY-MM-DD"));
                setStartTime(e.startTime);
                setEndTime(e.endTime);
                setModalShow(true);
              }
            }}
          />
          <div className={!isMobile && "col-md-12 col-lg-8"}>
            <div
              className="calendar col-right"
              style={{
                position: "relative",
                margin: "0rem",
              }}
            >
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={
                  isMobile
                    ? {
                        left: "prev",
                        center: "title,dayGridMonth,timeGridWeek,timeGridDay",
                        right: "next",
                      }
                    : {
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                      }
                }
                viewRender={(view, element) => console.log(view, element)}
                datesSet={(e) => {
                  handleMonthChnage(e);
                }}
                customButtons={{
                  new: {
                    text: "new",
                    click: () => console.log("new event"),
                  },
                }}
                events={event}
                eventColor="red"
                nowIndicator
                dateClick={(e) => handleDateClick(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Calendar;
